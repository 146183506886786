<template>
  <swiper class="swiper pt-5 pr-5 pl-5" :options="swiperOption">
    <swiper-slide>
      <img :src="require(`@/assets/VTB.png`)" class="square" alt="data-center">
      <h3 class="text-left">Банк ВТБ</h3>
      <p class="text-left">Системы видеоконференц связи, для офисов банка в Москве</p>
    </swiper-slide>
    <swiper-slide>
      <img :src="require(`@/assets/Evolution.jpeg`)" class="square" alt="data-center">
      <h3 class="text-left">НПФ Эволюция</h3>
      <p class="text-left">Программно-аппаратные комплексы для организации сети передачи данных</p>
    </swiper-slide>
    <swiper-slide>
      <img :src="require(`@/assets/MAI.jpeg`)" class="square" alt="data-center">
      <h3 class="text-left">Комплекс общежитий МАИ</h3>
      <p class="text-left">Разработка архитектуры ЛСВ, для доступа студентов в интернет</p>
    </swiper-slide>
    <swiper-slide>
      <img :src="require(`@/assets/Medci.jpeg`)" class="square" alt="data-center">
      <h3 class="text-left">Медси</h3>
      <p class="text-left">Создание мультимедийных систем в многофункциональном медицинском центре</p>
    </swiper-slide>
    <swiper-slide>
      <img :src="require(`@/assets/Gazprom.jpeg`)" class="square" alt="data-center">
      <h3 class="text-left">Газпром Нефть</h3>
      <p class="text-left">Cоздание мультимедийной среды конференц-зала</p>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'

  export default {
    name: 'SwiperProjects',
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        swiperOption: {
          slidesPerView: 4,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
    }
  }
</script>

<style scoped>
.square {
  height: 200px;
  width: 100%;
  object-fit: cover;
}
</style>