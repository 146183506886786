<template>
    <div class="container-fluid" id="directions">   
        <div class="row pt-5">
            <div class="col-10 pl-0 text-right">
                <h1 class="background-secondary pr-5 pt-2 pb-2 color-white">НАПРАВЛЕНИЯ КОМПАНИИ</h1>
            </div>

        </div>

        <div class="pt-5 row d-flex justify-content-center">
            <div class="relative">
                <img class="image pr-5 pl-5" :src="require(`@/assets/audio-video.png`)" alt="">
                <h2 class="overlay-text image-padding no-hover">Аудио/видео решения</h2>
            </div>
            <div class="relative">
                <img class="image pr-5 pl-5" :src="require(`@/assets/automation.png`)" alt="">
                <h2 class="overlay-text image-padding no-hover">Автоматизация и диспетчеризация</h2>
            </div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="relative">
                <img class="image pr-5 pl-5" :src="require(`@/assets/information-security.png`)" alt="">
                <h2 class="overlay-text image-padding no-hover">Системы информационной безопасности</h2>
            </div>
            <div class="relative">
                <img class="image pr-5 pl-5" :src="require(`@/assets/infrastructure.png`)" alt="">
                <h2 class="overlay-text image-padding no-hover">IT инфраструктура</h2>
            </div>
            <div class="relative">
                <img class="image pr-5 pl-5" :src="require(`@/assets/intercom.png`)" alt="">
                <h2 class="overlay-text image-padding-1 no-hover">Видеонаблюдение, домофония</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeDirections'
}
</script>

<style scoped>

.image:hover {
    filter: brightness(0.3);
}

.image:hover + .overlay-text {
    opacity: 1;
}

.no-hover {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: default;
    pointer-events: none;
}

/* .overlay-text:hover {
    display: block;
} */


.overlay-text {
    color: #18D1FF;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    opacity: 0;
    z-index: 10;
    transition-duration: 0.5s;
    animation-duration: 0.5s;
}

.image-padding {
    top: 50%;
}

.image-padding-1 {
    top: 56%;
}

.image {
    height: 300px;
    object-fit: cover;
    z-index: 2;
    transition-duration: 0.5s;
    animation-duration: 0.5s;
}

.relative {
    position: relative;
}

.background-secondary {
    background-color: #18D1FF;
}

.color-white {
    color: white;
}

</style>