<template>
  <div id="app">

    <Header/>
    <Home/>
    <HomeAbout/>
    <HomeDirections/>
    <HomeExpertise/>
    <HomeProjects/>
    <HomeContacts/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Home from './components/Home.vue'
import HomeAbout from './components/HomeAbout.vue'
import HomeDirections from './components/HomeDirections.vue'
import HomeExpertise from './components/HomeExpertise.vue'
import HomeProjects from './components/HomeProjects.vue'
import HomeContacts from './components/HomeContacts.vue'
import Footer from './components/Footer.vue'



export default {
  name: 'App',
  components: {
    Header,
    Home,
    HomeAbout,
    HomeDirections,
    HomeExpertise,
    HomeProjects,
    HomeContacts,
    Footer
  },
  metaInfo: {
    title: 'NetStell',
    titleTemplate: '%s | Решения для вашего бизнеса'
  },
  methods: {
    scrollIntoView(event) {
      event.preventDefault()
      const href = event.target.getAttribute('href')
      const el = href ? document.querySelector(href) : null
      if (el) {
        this.$refs.content.scrollTop = el.offsetTop
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  font-family: 'Montserrat', sans-serif;
}

#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
