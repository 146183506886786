<template>
    <div class="container-fluid pt-5">
        <div class="row pt-5 pb-5 background-primary pl-5 pr-5">
            <div class="col-3 text-left white">
                <h3>Меню</h3>
                <a href="#home" @click="scrollIntoView">
                    <p>Главная</p>
                </a>
                <a href="#about" @click="scrollIntoView">
                    <p>О компании</p>
                </a>
                <a href="#projects" @click="scrollIntoView">
                    <p>Проекты</p>
                </a>
            </div>
            <div class="col-3 text-left white">
                <!-- <h3>Направления компании</h3>
                <p>Аудит</p>
                <p>Проектирование</p>
                <p>Поставки</p>
                <p>Установка и интеграция</p>
                <p>Техническое сопровождение</p> -->
            </div>
            <div class="col-3 text-left white">
                <!-- <h3>Технологии и решения</h3>
                <p>Центры обработки данных</p>
                <p>Сети передачи данных</p>
                <p>Мультимедийные системы</p>
                <p>Телефония</p>
                <p>Видеоконференцсвязь</p>
                <p>Системные и прикладные программные решения</p> -->
            </div>
            <div class="col-3 text-left white">
                <h3>Контакты</h3>
                <p>123022, Москва, ул. 1905 Года, 10А стр. 1, ООО «Нетстелл»</p>
                <a href="tel:+74993473048">
                    <p>+7 (499) 347-30-48</p>
                </a>
                <a href="mailto:info@netstell.ru">
                    <p>info@netstell.ru</p>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.background-primary {
    background-color: #001E54;
}
.white {
    color: white;
}

a {
    color: white;
}

a:hover {
    text-decoration: none;
}
</style>