<template>
    <div class="container-fluid">
        <div class="row width-100">
            <b-navbar toggleable="xl" type="light" class="col-12">
                <b-navbar-brand to="#" class="pl-5 pr-5">
                    <img :src="require(`@/assets/logo.png`)" alt="logo" height="50vh" class="d-inline-block align-top">
                </b-navbar-brand>
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item href="#about" @click="scrollIntoView" class="but-font pl-xl-4 pr-xl-4 active">О НАС</b-nav-item>
                        <b-nav-item href="#directions" @click="scrollIntoView" class="but-font pl-xl-4 pr-xl-4 active">НАПРАВЛЕНИЯ</b-nav-item>
                        <b-nav-item href="#expertise" @click="scrollIntoView" class="but-font pl-xl-4 pr-xl-4 active">ЭКСПЕРТИЗА</b-nav-item>
                        <b-nav-item href="#projects" @click="scrollIntoView" class="but-font pl-xl-4 pr-xl-4 active">ПРОЕКТЫ</b-nav-item>
                        <b-nav-item class="but-font pl-xl-5 text-primary" href="tel:+74993473048">+7 (499) 347-30-48</b-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.width-100 {
    z-index: 0;
    width: 100%;
}
.space-between {
    display: flex;
    justify-content: space-between;
}
.but-font {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.margin-0 {
    margin: 0;
}

</style>