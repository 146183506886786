<template>
    <div id="about">
        <div class="container-fluid">
            <div class="row pt-5">
                <div class="col-12">
                    <h1 class="color-primary text-left pl-5">О КОМПАНИИ</h1>
                </div>
            </div>

            <div class="row pt-5">
                <div class="d-none d-xl-block row position-relative width-100">
                    <div class="col-8 pt-5 pb-5 pr-5 pl-5 background-primary">
                        <div class="row">
                            <p class="col-8 text-left color-white">NetStell - команда, объединяющая профессиональные кадры с глубокой многолетней экспертизой в реализации проектов, связанных с созданием и развитием информационной платформы современного бизнеса различного масштаба и направленности. Основная деятельность компании включает разработку информационных систем и их отдельных сегментов, оптимизацию готовых решений в IT инфраструктуре за счет собственных программных доработок и аппаратной модернизации, проектирование и строительство телекоммуникационных сетей, центров обработки данных, диспетчерских и инженерных систем.</p>

                        </div>
                        <!-- <div class="row pl-3 pt-5">
                            <button type="button" class="col-4 btn btn-light">Подробнее</button>
                        </div> -->
                    </div>
                    <img class="image position-absolute image-row" :src="require(`@/assets/homeimg1.jpg`)" alt="office img">
                </div>
                <div class="d-xl-none row width-100">
                    <div class="row background-primary">
                        <p class="col-12 text-left color-white pt-5 pb-5 pl-5 pr-5">NetStell - команда, объединяющая профессиональные кадры с глубокой многолетней экспертизой в реализации проектов, связанных с созданием и развитием информационной платформы современного бизнеса различного масштаба и направленности. Основная деятельность компании включает разработку информационных систем и их отдельных сегментов, оптимизацию готовых решений в IT инфраструктуре за счет собственных программных доработок и аппаратной модернизации, проектирование и строительство телекоммуникационных сетей, центров обработки данных, диспетчерских и инженерных систем.</p>
                    </div>

                    <img class="img-sm" :src="require(`@/assets/homeimg1.jpg`)" alt="">

                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.color-white {
    color: white;
}
.color-primary {
    color: #142B51;
}
.background-primary {
    background-color: #001E54;
}
.image-row {
    right: 100px;
    top: 55px; 
}

.img-sm {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.width-100 {
    z-index: 0;
    width: 100%;
}
p {
    line-height: 38.5px;
}

.image {
    z-index: 1;
    height: 350px;
    box-shadow: rgba(43, 43, 43, 0.4) 0px 0px 100px;
}
</style>

<script>
export default {
    name: 'HomeAbout'
}
</script>