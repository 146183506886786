<template>
    <div class="container-fluid">
        <div class="row pt-5">
            <div class="col-12">
                <h1 class="text-center">КОНТАКТЫ</h1>

            </div>
        </div>
        <div class="row pl-5 pr-5 pt-5">
            <div class="col-5 text-left">
                <h3>Адрес:</h3>
                <p>123022, Москва, ул. 1905 Года, 10А стр. 1, ООО «Нетстелл»</p>
                <h3>Телефон:</h3>
                <a href="tel:+74993473048">
                    <p>+7 (499) 347-30-48</p>
                </a>
                <h3>E-mail:</h3>
                <a href="mailto:info@netstell.ru">
                    <p>info@netstell.ru</p>
                </a>
            </div>
            <div class="col-7">
                <div style="position:relative;overflow:hidden;">
                    <a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Москва</a>
                    <a href="https://yandex.ru/maps/213/moscow/house/ulitsa_1905_goda_10as1/Z04YcwJpTE0BQFtvfXt3dnhmbA==/?ll=37.559586%2C55.767459&utm_medium=mapframe&utm_source=maps&z=16.89" style="color:#eee;font-size:12px;position:absolute;top:14px;">1905 Года, 10Ас1 — Yandex.Maps</a>
                    <iframe class="square" src="https://yandex.ru/map-widget/v1/-/CCUurPAfHC" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeContacts'
}
</script>

<style scoped>
.square {
  height: 400px;
  width: 100%;
}
</style>