<template>
  <swiper class="swiper pt-5 pr-5 pl-5" :options="swiperOption">
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.dell.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/dellemc.png`)" class="square" alt="dell">
            </a>
        </div>
        <div class="row">
            <a href="https://www.se.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/schneider.png`)" class="square" alt="schneider">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.avaya.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/avaya.png`)" class="square" alt="avaya">
            </a>
        </div>
        <div class="row">
            <a href="https://atos.net/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/bull.png`)" class="square" alt="atos">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.huawei.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/huawei.png`)" class="square" alt="huawei">
            </a>
        </div>
        <div class="row">
            <a href="https://www.hp.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/hp.png`)" class="square" alt="hp">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.microsoft.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/microsoft.png`)" class="square" alt="microsoft">
            </a>
        </div>
        <div class="row">
            <a href="https://www.h3c.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/h3c.png`)" class="square" alt="h3c">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://global.abb/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/abb.png`)" class="square" alt="abb">
            </a>
        </div>
        <div class="row">
            <a href="https://www.oracle.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/oracle.png`)" class="square" alt="oracle">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.poly.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/polycom.png`)" class="square" alt="polycom">
            </a>
        </div>
        <div class="row">
            <a href="https://www.veritas.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/veritas.png`)" class="square" alt="veritas">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.crestron.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/crestron.png`)" class="square" alt="crestron">
            </a>
        </div>
        <div class="row">
            <a href="https://www.extron.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/extron.png`)" class="square" alt="extron">
            </a>
        </div>
    </swiper-slide>
    <swiper-slide class="container">
        <div class="row">
            <a href="https://www.legrand.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/legrand.png`)" class="square" alt="legrand">
            </a>
        </div>
        <div class="row">
            <a href="https://www.unilumin.com/" target="_blank" rel="noopener noreferrer">
                <img :src="require(`@/assets/unilumin.png`)" class="square" alt="unilumin">
            </a>
        </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
  import 'swiper/swiper-bundle.css'

  export default {
    name: 'SwiperPartners',
    components: {
      Swiper,
      SwiperSlide
    },
    data() {
      return {
        swiperOption: {
          slidesPerView: 4,
          spaceBetween: 30,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          }
        }
      }
    }
  }
</script>

<style scoped>
.square {
    height: 200px;
    width: 300px;
    object-fit: contain;
    filter: grayscale(100%); 
    transition-duration: 0.5s;
    animation-duration: 0.5s;
}

.square:hover {
    filter: none;
}


</style>