<template>
    <div class="container-fluid" id="expertise">
        <div class="row pt-5">
            <div class="col-5"></div>
            <div class="col-7 pr-0 text-left">
                <h1 class="background-secondary pl-5 pt-2 pb-2 color-white">ЭКСПЕРТИЗА</h1>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-12">
                <h2>ТЕХНОЛОГИИ И РЕШЕНИЯ:</h2>
            </div>
        </div>

        <div class="row pt-5">
            <div class="col-4 pr-5 pl-5">
                <img :src="require(`@/assets/data-center.jpeg`)" class="square" alt="data-center">
                <h3 class="text-left pt-3">Центры обработки данных</h3>
                <p class="text-left">Проектирование, внедрение, и запуск комплексной среды для организации ЦОД, включая: вычислительные ресурсы, системы хранения данных, инженерное оборудование.</p>
                <!-- <button type="button" class="col-4 btn btn-light float-left">Подробнее</button> -->

            </div>
            <div class="col-4 pr-5 pl-5">
                <img :src="require(`@/assets/corporate-network.jpeg`)" class="square" alt="data-center">
                <h3 class="text-left pt-3">Корпоративные сети передачи данных</h3>
                <p class="text-left">Создание, поддержка, и развитие унифицированной платформы для бизнес коммуникаций, с использованием различных каналов: электронная почта, голосовая связь, веб-сервисы, видео-конференции.</p>
                <!-- <button type="button" class="col-4 btn btn-light float-left">Подробнее</button> -->

            </div>
            <div class="col-4 pr-5 pl-5">
                <img :src="require(`@/assets/multimedia-complex.jpeg`)" class="square" alt="data-center">
                <h3 class="text-left pt-3">Мультимедийные комплексы</h3>
                <p class="text-left">Разработка архитектуры, построение, и сопровождение технических решений для передачи и отображения мультимедийного контента (организация переговорных комнат, конференц-залов, ситуационных центров).</p>
                <!-- <button type="button" class="col-4 btn btn-light float-left">Подробнее</button> -->

            </div>
            
        </div>

        <div class="row">
            <SwiperPartners />
        </div>
    </div>
    
</template>

<script>
import SwiperPartners from './SwiperPartners.vue'
export default {
    components: { SwiperPartners },
    name: 'HomeExpertise'
}
</script>

<style scoped>

.background-secondary {
    background-color: #18D1FF;
}

.color-white {
    color: white;
}

.square {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
</style>